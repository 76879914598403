import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listComment, deleteComment } from '@/api/business/comment';
import { replyComment } from '@/api/business/order';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Comment',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        userId: undefined,
        valueId: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      replyForm: {
        commentId: 0,
        content: ''
      },
      replyFormVisible: false
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listComment(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleReply: function handleReply(row) {
      this.replyForm = { commentId: row.id, content: '' };
      this.replyFormVisible = true;
    },
    reply: function reply() {
      var _this2 = this;

      replyComment(this.replyForm).then(function (response) {
        _this2.replyFormVisible = false;
        _this2.$notify.success({
          title: '成功',
          message: '回复成功'
        });
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      deleteComment(row).then(function (response) {
        _this3.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success',
          duration: 2000
        });
        var index = _this3.list.indexOf(row);
        _this3.list.splice(index, 1);
      });
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['评论ID', '用户ID', '商品ID', '评论', '评论图片列表', '评论时间'];
        var filterVal = ['id', 'userId', 'valueId', 'content', 'picUrls', 'addTime'];
        excel.export_json_to_excel2(tHeader, _this4.list, filterVal, '商品评论信息');
        _this4.downloadLoading = false;
      });
    }
  }
};