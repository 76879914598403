import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listRole, createRole, updateRole, deleteRole, getPermission, updatePermission as _updatePermission } from '@/api/business/role';
import Pagination from '@/components/Pagination';
export default {
  name: 'Role',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '角色名称不能为空', trigger: 'blur' }]
      },
      permissionDialogFormVisible: false,
      systemPermissions: null,
      assignedPermissions: null,
      permissionForm: {
        roleId: undefined,
        permissions: []
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listRole(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createRole(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '添加角色成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateRole(_this5.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this5.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新管理员成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      deleteRole(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '删除管理员成功'
        });
        var index = _this6.list.indexOf(row);
        _this6.list.splice(index, 1);
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handlePermission: function handlePermission(row) {
      var _this7 = this;

      this.permissionDialogFormVisible = true;
      this.permissionForm.roleId = row.id;
      getPermission({ roleId: row.id }).then(function (response) {
        _this7.systemPermissions = response.data.data.systemPermissions;
        _this7.assignedPermissions = response.data.data.assignedPermissions;
      });
    },
    updatePermission: function updatePermission() {
      var _this8 = this;

      this.permissionForm.permissions = this.$refs.tree.getCheckedKeys(true);
      _updatePermission(this.permissionForm).then(function (response) {
        _this8.permissionDialogFormVisible = false;
        _this8.$notify.success({
          title: '成功',
          message: '授权成功'
        });
      }).catch(function (response) {
        _this8.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    }
  }
};