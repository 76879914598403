var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入专题标题"
            },
            model: {
              value: _vm.listQuery.title,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "title", $$v)
              },
              expression: "listQuery.title"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入专题子标题"
            },
            model: {
              value: _vm.listQuery.subtitle,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "subtitle", $$v)
              },
              expression: "listQuery.subtitle"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/topic/list"],
                  expression: "['GET /admin/topic/list']"
                }
              ],
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["POST /admin/topic/create"],
                  expression: "['POST /admin/topic/create']"
                }
              ],
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                size: "mini",
                type: "warning",
                icon: "el-icon-download"
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "专题标题",
              "min-width": "200",
              prop: "title"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "专题子标题",
              "min-width": "200",
              prop: "subtitle"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "picUrl", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      attrs: { src: scope.row.picUrl, width: "100" }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "专题详情", prop: "content" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          visible: _vm.contentDialogVisible,
                          title: "专题详情"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.contentDialogVisible = $event
                          }
                        }
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.contentDetail) }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.showContent(scope.row.content)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "底价", prop: "price" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "阅读数量",
              prop: "readCount",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "min-width": "150",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/topic/update"],
                            expression: "['POST /admin/topic/update']"
                          }
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/topic/delete"],
                            expression: "['POST /admin/topic/delete']"
                          }
                        ],
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "返回顶部" } },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "专题标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "title", $$v)
                      },
                      expression: "dataForm.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专题子标题", prop: "subtitle" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.subtitle,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "subtitle", $$v)
                      },
                      expression: "dataForm.subtitle"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专题图片", prop: "picUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif"
                      }
                    },
                    [
                      _vm.dataForm.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.dataForm.picUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品序号或ID" } },
                [
                  _vm._l(_vm.dataForm.goods, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { closable: "", type: "primary" },
                        on: {
                          close: function($event) {
                            _vm.handleClose(tag)
                          }
                        }
                      },
                      [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm.newGoodsVisible
                    ? _c("el-input", {
                        ref: "newGoodsInput",
                        staticClass: "input-new-goods",
                        attrs: { size: "small" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          }
                        },
                        model: {
                          value: _vm.newGoodIdSn,
                          callback: function($$v) {
                            _vm.newGoodIdSn = $$v
                          },
                          expression: "newGoodIdSn"
                        }
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-keyword",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.showInput }
                        },
                        [_vm._v("+ 增加")]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "700px" },
                  attrs: { label: "专题内容" }
                },
                [
                  _c("editor", {
                    attrs: { init: _vm.editorInit },
                    model: {
                      value: _vm.dataForm.content,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "content", $$v)
                      },
                      expression: "dataForm.content"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品低价", prop: "price" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.price,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "price", $$v)
                      },
                      expression: "dataForm.price"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "阅读量", prop: "readCount" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.readCount,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "readCount", $$v)
                      },
                      expression: "dataForm.readCount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }