var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "small",
              placeholder: "请输入用户名"
            },
            model: {
              value: _vm.listQuery.username,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "username", $$v)
              },
              expression: "listQuery.username"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "small",
              placeholder: "请输入手机号"
            },
            model: {
              value: _vm.listQuery.mobile,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "mobile", $$v)
              },
              expression: "listQuery.mobile"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", size: "mini", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                size: "mini",
                type: "warning",
                icon: "el-icon-download"
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100px",
              label: "用户ID",
              prop: "id",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户名", prop: "nickname" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "手机号码", prop: "mobile" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "性别", prop: "gender" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.genderDic[scope.row.gender]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "生日", prop: "birthday" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户等级", prop: "userLevel" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.levelDic[scope.row.userLevel]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.statusDic[scope.row.status]) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "200",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 0 && scope.row.userLevel == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["GET /admin/user/detailApprove"],
                                expression: "['GET /admin/user/detailApprove']"
                              }
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("推广代理")]
                        )
                      : scope.row.status == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/user/approveAgency"],
                                expression: "['POST /admin/user/approveAgency']"
                              }
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleApproveAgency(scope.row)
                              }
                            }
                          },
                          [_vm._v("审批")]
                        )
                      : _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["GET /admin/user/detailApprove"],
                                expression: "['GET /admin/user/detailApprove']"
                              }
                            ],
                            attrs: { type: "info", size: "mini" }
                          },
                          [_vm._v("非代理")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.detailDialogVisible,
            title: "代理详情",
            width: "700"
          },
          on: {
            "update:visible": function($event) {
              _vm.detailDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { data: _vm.agencyDetail, "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "佣金比例(%)" } }, [
                _c("span", [_vm._v(_vm._s(_vm.agencyDetail.settlementRate))])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "推广二维码" } }, [
                _c("img", {
                  attrs: { src: _vm.agencyDetail.shareUrl, width: "300" }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.approveDialogVisible, title: "代理审批" },
          on: {
            "update:visible": function($event) {
              _vm.approveDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "approveForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.approveForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "佣金比例(%)", prop: "settlementRate" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.approveForm.settlementRate,
                      callback: function($$v) {
                        _vm.$set(_vm.approveForm, "settlementRate", $$v)
                      },
                      expression: "approveForm.settlementRate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.approveDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmApprove }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }