//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listArticle, deleteArticle } from '@/api/business/article';
import BackToTop from '@/components/BackToTop';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'ArticleList',
  components: { BackToTop: BackToTop, Pagination: Pagination },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        title: undefined,
        sort: 'add_time',
        order: 'desc'
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listArticle(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCreate: function handleCreate() {
      this.$router.push({ path: '/promotion/articleCreate' });
    },
    handleUpdate: function handleUpdate(row) {
      this.$router.push({ path: '/promotion/articleEdit', query: { id: row.id } });
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      deleteArticle(row).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '删除成功'
        });
        var index = _this2.list.indexOf(row);
        _this2.list.splice(index, 1);
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    }
  }
};