var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("编辑信息公告")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "article",
              attrs: {
                rules: _vm.rules,
                model: _vm.article,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.article.type,
                        callback: function($$v) {
                          _vm.$set(_vm.article, "type", $$v)
                        },
                        expression: "article.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("公告")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("通知")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.article.title,
                      callback: function($$v) {
                        _vm.$set(_vm.article, "title", $$v)
                      },
                      expression: "article.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容" } },
                [
                  _c("editor", {
                    attrs: { init: _vm.editorInit },
                    model: {
                      value: _vm.article.content,
                      callback: function($$v) {
                        _vm.$set(_vm.article, "content", $$v)
                      },
                      expression: "article.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
            [_vm._v("更新")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }