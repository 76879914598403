//
//
//
//
//
//

import { statOrder } from '@/api/business/stat';
import VeLine from 'v-charts/lib/line';
export default {
  components: { VeLine: VeLine },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;

    statOrder().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'orders': '订单量',
          'customers': '下单用户',
          'amount': '订单总额',
          'pcr': '客单价'
        }
      };
      _this.chartExtend = {
        xAxis: { boundaryGap: true }
      };
    });
  }
};