var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", size: "mini", placeholder: "请输入用户ID" },
            model: {
              value: _vm.listQuery.userId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入订单编号"
            },
            model: {
              value: _vm.listQuery.orderSn,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "orderSn", $$v)
              },
              expression: "listQuery.orderSn"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                multiple: "",
                size: "mini",
                placeholder: "请选择订单状态"
              },
              model: {
                value: _vm.listQuery.orderStatusArray,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "orderStatusArray", $$v)
                },
                expression: "listQuery.orderStatusArray"
              }
            },
            _vm._l(_vm.statusMap, function(key, value) {
              return _c("el-option", {
                key: key,
                attrs: { label: key, value: value }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/order/list"],
                  expression: "['GET /admin/order/list']"
                }
              ],
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                size: "mini",
                type: "warning",
                icon: "el-icon-download"
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "订单编号",
              prop: "orderSn",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "用户ID",
              prop: "userId"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "订单状态",
              prop: "orderStatus"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          _vm._f("orderStatusFilter")(scope.row.orderStatus)
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "订单金额",
              prop: "orderPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "支付金额",
              prop: "actualPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120px",
              label: "支付时间",
              prop: "payTime"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "120px",
              label: "物流单号",
              prop: "shipSn"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "物流渠道",
              prop: "shipChannel"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "min-width": "150px",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/order/detail"],
                            expression: "['GET /admin/order/detail']"
                          }
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.orderStatus == 201
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/ship"],
                                expression: "['POST /admin/order/ship']"
                              }
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleShip(scope.row)
                              }
                            }
                          },
                          [_vm._v("发货")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderStatus == 202
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/refund"],
                                expression: "['POST /admin/order/refund']"
                              }
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleRefund(scope.row)
                              }
                            }
                          },
                          [_vm._v("退款")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.orderDialogVisible,
            title: "订单详情",
            width: "800"
          },
          on: {
            "update:visible": function($event) {
              _vm.orderDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { data: _vm.orderDetail, "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "订单编号" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.orderSn))])
              ]),
              _vm._v(" "),
              _c("el-form-item", {
                attrs: { label: "订单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tag", [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderStatusFilter")(
                                scope.order.orderStatus
                              )
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单用户" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.user.nickname))])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户留言" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.message))])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货信息" } }, [
                _c("span", [
                  _vm._v("（收货人）" + _vm._s(_vm.orderDetail.order.consignee))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（手机号）" + _vm._s(_vm.orderDetail.order.mobile))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（地址）" + _vm._s(_vm.orderDetail.order.address))
                ])
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品信息" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.orderDetail.orderGoods,
                        size: "small",
                        border: "",
                        fit: "",
                        "highlight-current-row": ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品名称",
                          prop: "goodsName"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品编号",
                          prop: "goodsSn"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "货品规格",
                          prop: "specifications"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "货品价格",
                          prop: "price"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "货品数量",
                          prop: "number"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "货品图片",
                          prop: "picUrl"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("img", {
                                  attrs: { src: scope.row.picUrl, width: "40" }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "费用信息" } }, [
                _c("span", [
                  _vm._v(
                    "\n          (实际费用)" +
                      _vm._s(_vm.orderDetail.order.actualPrice) +
                      "元 =\n          (商品总价)" +
                      _vm._s(_vm.orderDetail.order.goodsPrice) +
                      "元 +\n          (快递费用)" +
                      _vm._s(_vm.orderDetail.order.freightPrice) +
                      "元 -\n          (优惠减免)" +
                      _vm._s(_vm.orderDetail.order.couponPrice) +
                      "元 -\n          (积分减免)" +
                      _vm._s(_vm.orderDetail.order.integralPrice) +
                      "元\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "支付信息" } }, [
                _c("span", [_vm._v("（支付渠道）微信支付")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（支付时间）" + _vm._s(_vm.orderDetail.order.payTime))
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "快递信息" } }, [
                _c("span", [
                  _vm._v(
                    "（快递公司）" + _vm._s(_vm.orderDetail.order.shipChannel)
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（快递单号）" + _vm._s(_vm.orderDetail.order.shipSn))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "（发货时间）" + _vm._s(_vm.orderDetail.order.shipTime)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货信息" } }, [
                _c("span", [
                  _vm._v(
                    "（确认收货时间）" +
                      _vm._s(_vm.orderDetail.order.confirmTime)
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.shipDialogVisible, title: "发货" },
          on: {
            "update:visible": function($event) {
              _vm.shipDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "shipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.shipForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.shipForm.shipChannel,
                        callback: function($$v) {
                          _vm.$set(_vm.shipForm, "shipChannel", $$v)
                        },
                        expression: "shipForm.shipChannel"
                      }
                    },
                    _vm._l(_vm.shipChannelList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.shipDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmShip } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.refundDialogVisible, title: "退款" },
          on: {
            "update:visible": function($event) {
              _vm.refundDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refundForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.refundForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退款金额", prop: "refundMoney" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.refundForm.refundMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.refundForm, "refundMoney", $$v)
                      },
                      expression: "refundForm.refundMoney"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.refundDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefund }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }