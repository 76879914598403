import _extends from 'babel-runtime/helpers/extends';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import enLocale from './en';
import zhLocale from './zh';

Vue.use(VueI18n);

var messages = {
  en: _extends({}, enLocale, elementEnLocale),
  zh: _extends({}, zhLocale, elementZhLocale)
};

var i18n = new VueI18n({
  // set locale
  // options: en | zh
  locale: Cookies.get('language') || 'zh',
  // set locale messages
  messages: messages
});

export default i18n;