import { render, staticRenderFns } from "./region.vue?vue&type=template&id=41b038b6&"
import script from "./region.vue?vue&type=script&lang=js&"
export * from "./region.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.3.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Administrator\\Desktop\\cycode\\xiejiyuan\\admin-ui\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('41b038b6', component.options)
    } else {
      api.reload('41b038b6', component.options)
    }
    module.hot.accept("./region.vue?vue&type=template&id=41b038b6&", function () {
      api.rerender('41b038b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\views\\mall\\region.vue"
export default component.exports