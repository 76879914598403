import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listTopic, createTopic, updateTopic, deleteTopic } from '@/api/business/topic';
import { createStorage, uploadPath } from '@/api/business/storage';
import BackToTop from '@/components/BackToTop';
import Editor from '@tinymce/tinymce-vue';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import { getToken } from '@/utils/auth';

export default {
  name: 'Topic',
  components: { BackToTop: BackToTop, Editor: Editor, Pagination: Pagination },
  data: function data() {
    return {
      uploadPath: uploadPath,
      newGoodsVisible: false,
      newGoodIdSn: '',
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        title: undefined,
        subtitle: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        titile: undefined,
        subtitle: undefined,
        picUrl: undefined,
        content: '',
        price: undefined,
        readCount: undefined,
        goods: []
      },
      contentDetail: '',
      contentDialogVisible: false,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        title: [{ required: true, message: '专题标题不能为空', trigger: 'blur' }],
        subtitle: [{ required: true, message: '专题子标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '专题内容不能为空', trigger: 'blur' }]
      },
      downloadLoading: false,
      editorInit: {
        language: 'zh_CN',
        convert_urls: false,
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          createStorage(formData).then(function (res) {
            success(res.data.data.url);
          }).catch(function () {
            failure('上传失败，请重新上传');
          });
        }
      }
    };
  },

  computed: {
    headers: function headers() {
      return {
        'X-Dts-Admin-Token': getToken()
      };
    }
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listTopic(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        titile: undefined,
        subtitle: undefined,
        picUrl: undefined,
        content: '',
        price: undefined,
        readCount: undefined,
        goods: []
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },

    uploadPicUrl: function uploadPicUrl(response) {
      this.dataForm.picUrl = response.data.url;
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createTopic(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建专题成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    showContent: function showContent(content) {
      this.contentDetail = content;
      this.contentDialogVisible = true;
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateTopic(_this5.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this5.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新专题成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      deleteTopic(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '删除专题成功'
        });
        var index = _this6.list.indexOf(row);
        _this6.list.splice(index, 1);
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleClose: function handleClose(tag) {
      this.dataForm.goods.splice(this.dataForm.goods.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this7 = this;

      this.newGoodsVisible = true;
      this.$nextTick(function (_) {
        _this7.$refs.newGoodsInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newGoodIdSn = this.newGoodIdSn;
      if (newGoodIdSn) {
        this.dataForm.goods.push(newGoodIdSn);
      }
      this.newGoodsVisible = false;
      this.newGoodIdSn = '';
    },
    handleDownload: function handleDownload() {
      var _this8 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['专题ID', '专题标题', '专题子标题', '专题内容', '专题图片', '商品低价', '阅读量', '专题商品'];
        var filterVal = ['id', 'title', 'subtitle', 'content', 'picUrl', 'price', 'readCount', 'goods'];
        excel.export_json_to_excel2(tHeader, _this8.list, filterVal, '专题信息');
        _this8.downloadLoading = false;
      });
    }
  }
};