import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listStorage, createStorage, updateStorage, deleteStorage } from '@/api/business/storage';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Storage',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        key: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      createDialogVisible: false,
      dataForm: {
        id: undefined,
        name: ''
      },
      updateDialogVisible: false,
      rules: {
        name: [{ required: true, message: '对象名称不能为空', trigger: 'blur' }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listStorage(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: ''
      };
    },
    handleCreate: function handleCreate() {
      this.createDialogVisible = true;
    },
    handleUpload: function handleUpload(item) {
      var _this2 = this;

      var formData = new FormData();
      formData.append('file', item.file);
      createStorage(formData).then(function (response) {
        _this2.list.unshift(response.data.data);
        _this2.createDialogVisible = false;
        _this2.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }).catch(function () {
        _this2.$message.error('上传失败，请重新上传');
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      this.dataForm = _Object$assign({}, row);
      this.updateDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateStorage(_this4.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this4.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this4.dataForm.id) {
                  var index = _this4.list.indexOf(v);
                  _this4.list.splice(index, 1, _this4.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this4.updateDialogVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;

      deleteStorage(row).then(function (response) {
        _this5.$notify.success({
          title: '成功',
          message: '删除成功'
        });
        var index = _this5.list.indexOf(row);
        _this5.list.splice(index, 1);
      }).catch(function (response) {
        _this5.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this6 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['ID', '对象KEY', '对象名称', '对象类型', '对象大小', '访问链接'];
        var filterVal = ['id', 'key', 'name', 'type', 'size', 'url'];
        excel.export_json_to_excel2(tHeader, _this6.list, filterVal, '对象存储信息');
        _this6.downloadLoading = false;
      });
    }
  }
};