//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { info, chart } from '@/api/business/dashboard';
import CountTo from 'vue-count-to';
import LineChart from './LineChart';
import PieChart from './PieChart';
import BarChart from './BarChart';

export default {
  components: {
    CountTo: CountTo,
    LineChart: LineChart,
    PieChart: PieChart,
    BarChart: BarChart
  },
  data: function data() {
    return {
      userTotal: 0,
      goodsTotal: 0,
      productTotal: 0,
      orderTotal: 0,
      userOrderCnt: { dayData: [], userCnt: [], orderCnt: [] },
      orderAmts: { dayData: [], orderAmtData: [], orderCntData: [] },
      categorySell: { categoryNames: [], categorySellData: [] }
    };
  },
  mounted: function mounted() {},
  created: function created() {
    var _this = this;

    chart().then(function (response) {
      _this.userOrderCnt = response.data.data.userOrderCnt;
      _this.orderAmts = response.data.data.orderAmts;
      _this.categorySell = response.data.data.categorySell;
    });
    info().then(function (response) {
      _this.userTotal = response.data.data.userTotal;
      _this.goodsTotal = response.data.data.goodsTotal;
      _this.productTotal = response.data.data.productTotal;
      _this.orderTotal = response.data.data.orderTotal;
    });
  },

  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    }
  }
};