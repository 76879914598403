
import store from '@/store';

export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;

    var perms = store.getters && store.getters.perms;

    if (value && value instanceof Array && value.length > 0) {
      var permissions = value;

      var hasPermission = false;

      if (perms.indexOf('*') >= 0) {
        hasPermission = true;
      } else {
        hasPermission = perms.some(function (perm) {
          return permissions.includes(perm);
        });
      }

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('need perms! Like v-permission="[\'GET /aaa\',\'POST /bbb\']"');
    }
  }
};