import _getIterator from 'babel-runtime/core-js/get-iterator';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrollPane from '@/components/ScrollPane';
import { generateTitle } from '@/utils/i18n';

export default {
  components: { ScrollPane: ScrollPane },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    };
  },

  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    }
  },
  watch: {
    $route: function $route() {
      this.addViewTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.addViewTags();
  },

  methods: {
    generateTitle: generateTitle, // generateTitle by vue-i18n
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    addViewTags: function addViewTags() {
      var name = this.$route.name;

      if (name) {
        this.$store.dispatch('addView', this.$route);
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this = this;

      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _getIterator(tags), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var tag = _step.value;

            if (tag.to.path === _this.$route.path) {
              _this.$refs.scrollPane.moveToTarget(tag);

              // when query is different then update
              if (tag.to.fullPath !== _this.$route.fullPath) {
                _this.$store.dispatch('updateVisitedView', _this.$route);
              }

              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {
      var _this2 = this;

      this.$store.dispatch('delCachedView', view).then(function () {
        var fullPath = view.fullPath;

        _this2.$nextTick(function () {
          _this2.$router.replace({
            path: '/redirect' + fullPath
          });
        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this3 = this;

      this.$store.dispatch('delView', view).then(function (_ref) {
        var visitedViews = _ref.visitedViews;

        if (_this3.isActive(view)) {
          var latestView = visitedViews.slice(-1)[0];
          if (latestView) {
            _this3.$router.push(latestView);
          } else {
            _this3.$router.push('/');
          }
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this4 = this;

      this.$router.push(this.selectedTag);
      this.$store.dispatch('delOthersViews', this.selectedTag).then(function () {
        _this4.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags() {
      this.$store.dispatch('delAllViews');
      this.$router.push('/');
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      var offsetWidth = this.$el.offsetWidth; // container width
      var maxLeft = offsetWidth - menuMinWidth; // left boundary
      var left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;

      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};