import request from '@/utils/request';

export function listGoods(query) {
  return request({
    url: '/goods/list',
    method: 'get',
    params: query
  });
}

export function deleteGoods(data) {
  return request({
    url: '/goods/delete',
    method: 'post',
    data: data
  });
}

export function publishGoods(data) {
  return request({
    url: '/goods/create',
    method: 'post',
    data: data
  });
}

export function detailGoods(id) {
  return request({
    url: '/goods/detail',
    method: 'get',
    params: { id: id }
  });
}

export function editGoods(data) {
  return request({
    url: '/goods/update',
    method: 'post',
    data: data
  });
}

export function listCatAndBrand() {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  });
}