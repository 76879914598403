//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { detailArticle, editArticle } from '@/api/business/article';
import { createStorage, uploadPath } from '@/api/business/storage';
import Editor from '@tinymce/tinymce-vue';
import { MessageBox } from 'element-ui';
import { getToken } from '@/utils/auth';

export default {
  name: 'ArticleEdit',
  components: { Editor: Editor },
  data: function data() {
    return {
      uploadPath: uploadPath,
      article: {},
      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '信息内容不能为空', trigger: 'blur' }]
      },
      editorInit: {
        language: 'zh_CN',
        convert_urls: false,
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          createStorage(formData).then(function (res) {
            success(res.data.data.url);
          }).catch(function () {
            failure('上传失败，请重新上传');
          });
        }
      }
    };
  },

  computed: {
    headers: function headers() {
      return {
        'X-Dts-Admin-Token': getToken()
      };
    }
  },
  created: function created() {
    this.init();
  },

  methods: {
    init: function init() {
      var _this = this;

      if (this.$route.query.id == null) {
        return;
      }
      var articleId = this.$route.query.id;
      detailArticle(articleId).then(function (response) {
        _this.article = response.data.data;
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push({ path: '/promotion/articleList' });
    },
    handleEdit: function handleEdit() {
      var _this2 = this;

      editArticle(this.article).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '修改成功'
        });
        _this2.$router.push({ path: '/promotion/articleList' });
      }).catch(function (response) {
        MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
          confirmButtonText: '确定',
          type: 'error'
        });
      });
    }
  }
};