//
//
//
//
//
//

var tagAndTagSpacing = 4; // tagAndTagSpacing

export default {
  name: 'ScrollPane',
  data: function data() {
    return {
      left: 0
    };
  },

  methods: {
    handleScroll: function handleScroll(e) {
      var eventDelta = e.wheelDelta || -e.deltaY * 40;
      var $scrollWrapper = this.$refs.scrollContainer.$refs.wrap;
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4;
    },
    moveToTarget: function moveToTarget(currentTag) {
      var $container = this.$refs.scrollContainer.$el;
      var $containerWidth = $container.offsetWidth;
      var $scrollWrapper = this.$refs.scrollContainer.$refs.wrap;
      var tagList = this.$parent.$refs.tag;

      var firstTag = null;
      var lastTag = null;
      var prevTag = null;
      var nextTag = null;

      // find first tag and last tag
      if (tagList.length > 0) {
        firstTag = tagList[0];
        lastTag = tagList[tagList.length - 1];
      }

      // find preTag and nextTag
      for (var i = 0; i < tagList.length; i++) {
        if (tagList[i] === currentTag) {
          if (i === 0) {
            nextTag = tagList[i].length > 1 && tagList[i + 1];
          } else if (i === tagList.length - 1) {
            prevTag = tagList[i].length > 1 && tagList[i - 1];
          } else {
            prevTag = tagList[i - 1];
            nextTag = tagList[i + 1];
          }
          break;
        }
      }

      if (firstTag === currentTag) {
        $scrollWrapper.scrollLeft = 0;
      } else if (lastTag === currentTag) {
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth;
      } else {
        // the tag's offsetLeft after of nextTag
        var afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing;

        // the tag's offsetLeft before of prevTag
        var beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing;

        if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth;
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
        }
      }
    }
  }
};