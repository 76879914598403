//
//
//
//
//
//

import { statGoods } from '@/api/business/stat';
import VeLine from 'v-charts/lib/line';

export default {
  components: { VeLine: VeLine },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;

    statGoods().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'orders': '订单量',
          'products': '下单货品数量',
          'amount': '下单货品总额'
        }
      };
      _this.chartExtend = {
        xAxis: { boundaryGap: true }
      };
    });
  }
};