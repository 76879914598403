//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Page404',
  computed: {
    message: function message() {
      return '网管说这个页面你不能进......';
    }
  }
};