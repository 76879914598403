import _Promise from 'babel-runtime/core-js/promise';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (store.getters.token) {
    // 让每个请求携带token-- ['X-Dts-Admin-Token']为自定义key 请根据实际情况自行修改
    config.headers['X-Dts-Admin-Token'] = getToken();
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  _Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;

  if (res.errno === 501) {
    MessageBox.alert('系统未登录，请重新登录', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    });
    return _Promise.reject('error');
  } else if (res.errno === 502) {
    MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    });
    return _Promise.reject('error');
  } else if (res.errno === 503) {
    MessageBox.alert('请求业务目前未支持', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });
    return _Promise.reject('error');
  } else if (res.errno === 504) {
    MessageBox.alert('更新数据已经失效，请刷新页面重新操作', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });
    return _Promise.reject('error');
  } else if (res.errno === 505) {
    MessageBox.alert('更新失败，请再尝试一次', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });
    return _Promise.reject('error');
  } else if (res.errno === 506) {
    MessageBox.alert('没有操作权限，请联系管理员授权', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    });
    return _Promise.reject('error');
  } else if (res.errno !== 0) {
    // 非5xx的错误属于业务错误，留给具体页面处理
    return _Promise.reject(response);
  } else {
    return response;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message({
    message: '登录连接超时（后台不能连接，请联系系统管理员）',
    type: 'error',
    duration: 5 * 1000
  });
  return _Promise.reject(error);
});

export default service;