import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listAdmin, createAdmin, updateAdmin, deleteAdmin } from '@/api/business/admin';
import { roleOptions } from '@/api/business/role';
import { uploadPath } from '@/api/business/storage';
import { getToken } from '@/utils/auth';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Admin',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      uploadPath: uploadPath,
      list: null,
      total: 0,
      roleOptions: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: []
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        username: [{ required: true, message: '管理员名称不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      downloadLoading: false
    };
  },

  computed: {
    headers: function headers() {
      return {
        'X-Dts-Admin-Token': getToken()
      };
    }
  },
  created: function created() {
    var _this = this;

    this.getList();

    roleOptions().then(function (response) {
      _this.roleOptions = response.data.data;
    });
  },

  methods: {
    formatRole: function formatRole(roleId) {
      for (var i = 0; i < this.roleOptions.length; i++) {
        if (roleId === this.roleOptions[i].value) {
          return this.roleOptions[i].label;
        }
      }
      return '';
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listAdmin(this.listQuery).then(function (response) {
        _this2.list = response.data.data.items;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: []
      };
    },

    uploadAvatar: function uploadAvatar(response) {
      this.dataForm.avatar = response.data.url;
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createAdmin(_this4.dataForm).then(function (response) {
            _this4.list.unshift(response.data.data);
            _this4.dialogFormVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '添加管理员成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateAdmin(_this6.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this6.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this6.dataForm.id) {
                  var index = _this6.list.indexOf(v);
                  _this6.list.splice(index, 1, _this6.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this6.dialogFormVisible = false;
            _this6.$notify.success({
              title: '成功',
              message: '更新管理员成功'
            });
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      deleteAdmin(row).then(function (response) {
        _this7.$notify.success({
          title: '成功',
          message: '删除管理员成功'
        });
        var index = _this7.list.indexOf(row);
        _this7.list.splice(index, 1);
      }).catch(function (response) {
        _this7.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this8 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['管理员ID', '管理员名称', '管理员头像'];
        var filterVal = ['id', 'username', 'avatar'];
        excel.export_json_to_excel2(tHeader, _this8.list, filterVal, '管理员信息');
        _this8.downloadLoading = false;
      });
    }
  }
};