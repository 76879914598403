import _Promise from 'babel-runtime/core-js/promise';
import _extends from 'babel-runtime/helpers/extends';
import { asyncRouterMap, constantRouterMap } from '@/router';

/**
 * 通过meta.perms判断是否与当前用户权限匹配
 * @param perms
 * @param route
 */
function hasPermission(perms, route) {
  if (route.meta && route.meta.perms) {
    return perms.some(function (perm) {
      return route.meta.perms.includes(perm);
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param perms
 */
function filterAsyncRouter(routes, perms) {
  var res = [];

  routes.forEach(function (route) {
    var tmp = _extends({}, route);
    if (tmp.children) {
      tmp.children = filterAsyncRouter(tmp.children, perms);
      if (tmp.children && tmp.children.length > 0) {
        res.push(tmp);
      }
    } else {
      if (hasPermission(perms, tmp)) {
        res.push(tmp);
      }
    }
  });

  return res;
}

var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;

      return new _Promise(function (resolve) {
        var perms = data.perms;

        var accessedRouters = void 0;
        if (perms.includes('*')) {
          accessedRouters = asyncRouterMap;
        } else {
          accessedRouters = filterAsyncRouter(asyncRouterMap, perms);
        }
        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};

export default permission;