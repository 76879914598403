//
//
//
//
//
//
//


var version = require('element-ui/package.json').version; // element-ui version from node_modules
var ORIGINAL_THEME = '#409EFF'; // default color

export default {
  data: function data() {
    return {
      chalk: '', // content of theme-chalk css
      theme: ORIGINAL_THEME
    };
  },

  watch: {
    theme: function theme(val, oldVal) {
      var _this = this;

      if (typeof val !== 'string') return;
      var themeCluster = this.getThemeCluster(val.replace('#', ''));
      var originalCluster = this.getThemeCluster(oldVal.replace('#', ''));
      console.log(themeCluster, originalCluster);
      var getHandler = function getHandler(variable, id) {
        return function () {
          var originalCluster = _this.getThemeCluster(ORIGINAL_THEME.replace('#', ''));
          var newStyle = _this.updateStyle(_this[variable], originalCluster, themeCluster);

          var styleTag = document.getElementById(id);
          if (!styleTag) {
            styleTag = document.createElement('style');
            styleTag.setAttribute('id', id);
            document.head.appendChild(styleTag);
          }
          styleTag.innerText = newStyle;
        };
      };

      var chalkHandler = getHandler('chalk', 'chalk-style');

      if (!this.chalk) {
        var url = 'https://unpkg.com/element-ui@' + version + '/lib/theme-chalk/index.css';
        this.getCSSString(url, chalkHandler, 'chalk');
      } else {
        chalkHandler();
      }

      var styles = [].slice.call(document.querySelectorAll('style')).filter(function (style) {
        var text = style.innerText;
        return new RegExp(oldVal, 'i').test(text) && !/Chalk Variables/.test(text);
      });
      styles.forEach(function (style) {
        var innerText = style.innerText;

        if (typeof innerText !== 'string') return;
        style.innerText = _this.updateStyle(innerText, originalCluster, themeCluster);
      });
      this.$message({
        message: '换肤成功',
        type: 'success'
      });
    }
  },

  methods: {
    updateStyle: function updateStyle(style, oldCluster, newCluster) {
      var newStyle = style;
      oldCluster.forEach(function (color, index) {
        newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index]);
      });
      return newStyle;
    },
    getCSSString: function getCSSString(url, callback, variable) {
      var _this2 = this;

      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          _this2[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '');
          callback();
        }
      };
      xhr.open('GET', url);
      xhr.send();
    },
    getThemeCluster: function getThemeCluster(theme) {
      var tintColor = function tintColor(color, tint) {
        var red = parseInt(color.slice(0, 2), 16);
        var green = parseInt(color.slice(2, 4), 16);
        var blue = parseInt(color.slice(4, 6), 16);

        if (tint === 0) {
          // when primary color is in its rgb space
          return [red, green, blue].join(',');
        } else {
          red += Math.round(tint * (255 - red));
          green += Math.round(tint * (255 - green));
          blue += Math.round(tint * (255 - blue));

          red = red.toString(16);
          green = green.toString(16);
          blue = blue.toString(16);

          return '#' + red + green + blue;
        }
      };

      var shadeColor = function shadeColor(color, shade) {
        var red = parseInt(color.slice(0, 2), 16);
        var green = parseInt(color.slice(2, 4), 16);
        var blue = parseInt(color.slice(4, 6), 16);

        red = Math.round((1 - shade) * red);
        green = Math.round((1 - shade) * green);
        blue = Math.round((1 - shade) * blue);

        red = red.toString(16);
        green = green.toString(16);
        blue = blue.toString(16);

        return '#' + red + green + blue;
      };

      var clusters = [theme];
      for (var i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
      }
      clusters.push(shadeColor(theme, 0.1));
      return clusters;
    }
  }
};