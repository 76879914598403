//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCodeImg } from '@/api/login';
import Cookies from 'js-cookie';

export default {
  name: 'Login',
  data: function data() {
    return {
      codeUrl: '',
      cookiePassword: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
        code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
      },
      loading: false,
      redirect: undefined
    };
  },

  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    this.getCookie();
  },

  methods: {
    getCode: function getCode() {
      var _this = this;

      getCodeImg().then(function (res) {
        _this.codeUrl = 'data:image/gif;base64,' + res.data.data.img;
        _this.loginForm.uuid = res.data.data.uuid;
      });
    },
    getCookie: function getCookie() {
      var username = Cookies.get('username');
      var password = Cookies.get('password');
      var rememberMe = Cookies.get('rememberMe');
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          if (_this2.loginForm.rememberMe) {
            Cookies.set('username', _this2.loginForm.username, { expires: 30 });
            Cookies.set('password', _this2.loginForm.password, { expires: 30 });
            Cookies.set('rememberMe', _this2.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove('username');
            Cookies.remove('password');
            Cookies.remove('rememberMe');
          }
          _this2.$store.dispatch('LoginByUsername', _this2.loginForm).then(function () {
            _this2.loading = false;
            _this2.$router.push({ path: _this2.redirect || '/' });
          }).catch(function (response) {
            _this2.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
            _this2.loading = false;
            _this2.getCode();
          });
        }
      });
    }
  }
};