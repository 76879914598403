var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "table-layout" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("名称")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("介绍名称")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("标签")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("优惠券类型")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("最低消费")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("优惠面值")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.coupon.name))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.coupon.desc))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.coupon.tag))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm._f("formatType")(_vm.coupon.type)))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v("满" + _vm._s(_vm.coupon.min) + "元可用")
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v("减免" + _vm._s(_vm.coupon.discount) + "元")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("每人限额")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("当前状态")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("商品范围")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("有效期")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("优惠兑换码")]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticClass: "table-cell-title", attrs: { span: 4 } },
                [_vm._v("发行数量")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.coupon.limit))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm._f("formatStatus")(_vm.coupon.status)))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm._f("formatGoodsType")(_vm.coupon.goodsType)))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.getTimeScope()))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.coupon.code))
              ]),
              _vm._v(" "),
              _c("el-col", { staticClass: "table-cell", attrs: { span: 4 } }, [
                _vm._v(
                  _vm._s(_vm.coupon.total === 0 ? "不限" : _vm.coupon.total)
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入用户ID" },
            model: {
              value: _vm.listQuery.userId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { clearable: "", placeholder: "请选择使用状态" },
              model: {
                value: _vm.listQuery.status,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status"
              }
            },
            _vm._l(_vm.useStatusOptions, function(type) {
              return _c("el-option", {
                key: type.value,
                attrs: { label: type.label, value: type.value }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/coupon/listuser"],
                  expression: "['GET /admin/coupon/listuser']"
                }
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户优惠券ID",
              prop: "id",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID", prop: "userId" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "领取时间", prop: "addTime" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatUseStatus")(scope.row.status)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单ID", prop: "orderId" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用时间", prop: "usedTime" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }