//
//
//
//
//
//
//
//
//
//
//

import { generateTitle } from '@/utils/i18n';
import pathToRegexp from 'path-to-regexp';

export default {
  data: function data() {
    return {
      levelList: null
    };
  },

  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },

  methods: {
    generateTitle: generateTitle,
    getBreadcrumb: function getBreadcrumb() {
      var params = this.$route.params;

      var matched = this.$route.matched.filter(function (item) {
        if (item.name) {
          // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
          var toPath = pathToRegexp.compile(item.path);
          item.path = toPath(params);
          return true;
        }
      });
      var first = matched[0];
      if (first && first.name.trim().toLocaleLowerCase() !== 'Dashboard'.toLocaleLowerCase()) {
        matched = [{ path: '/dashboard', meta: { title: 'dashboard' } }].concat(matched);
      }
      this.levelList = matched;
    }
  }
};