import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { publishGoods, listCatAndBrand } from '@/api/business/goods';
import { createStorage, uploadPath } from '@/api/business/storage';
import Editor from '@tinymce/tinymce-vue';
import { MessageBox } from 'element-ui';
import { getToken } from '@/utils/auth';

export default {
  name: 'GoodsCreate',
  components: { Editor: Editor },

  data: function data() {
    return {
      uploadPath: uploadPath,
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      categoryList: [],
      brandList: [],
      goods: { picUrl: '', gallery: [] },
      specVisiable: false,
      specForm: { specification: '', value: '', picUrl: '' },
      multipleSpec: false,
      specifications: [{ specification: '规格', value: '标准', picUrl: '' }],
      productVisiable: false,
      productForm: { id: 0, specifications: [], price: 0.00, number: 0, url: '' },
      products: [{ id: 0, specifications: ['标准'], price: 0.00, number: 0, url: '' }],
      attributeVisiable: false,
      attributeForm: { attribute: '', value: '' },
      attributes: [],
      rules: {
        goodsSn: [{ required: true, message: '商品编号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '商品名称不能为空', trigger: 'blur' }]
      },
      editorInit: {
        language: 'zh_CN',
        convert_urls: false,
        plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
        toolbar: ['searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample', 'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var formData = new FormData();
          formData.append('file', blobInfo.blob());
          createStorage(formData).then(function (res) {
            success(res.data.data.url);
          }).catch(function () {
            failure('上传失败，请重新上传');
          });
        }
      }
    };
  },

  computed: {
    headers: function headers() {
      return {
        'X-Dts-Admin-Token': getToken()
      };
    }
  },
  created: function created() {
    this.init();
  },


  methods: {
    init: function init() {
      var _this = this;

      listCatAndBrand().then(function (response) {
        _this.categoryList = response.data.data.categoryList;
        _this.brandList = response.data.data.brandList;
      });
    },
    handleCategoryChange: function handleCategoryChange(value) {
      this.goods.categoryId = value[value.length - 1];
    },

    handleCancel: function handleCancel() {
      this.$router.push({ path: '/goods/list' });
    },
    handlePublish: function handlePublish() {
      var _this2 = this;

      var finalGoods = {
        goods: this.goods,
        specifications: this.specifications,
        products: this.products,
        attributes: this.attributes
      };
      publishGoods(finalGoods).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '创建成功'
        });
        _this2.$router.push({ path: '/goods/list' });
      }).catch(function (response) {
        MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
          confirmButtonText: '确定',
          type: 'error'
        });
      });
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.goods.keywords = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this3 = this;

      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword) {
        this.keywords.push(newKeyword);
        this.goods.keywords = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },

    uploadPicUrl: function uploadPicUrl(response) {
      this.goods.picUrl = response.data.url;
    },
    uploadOverrun: function uploadOverrun() {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      });
    },
    handleGalleryUrl: function handleGalleryUrl(response, file, fileList) {
      if (response.errno === 0) {
        this.goods.gallery.push(response.data.url);
      }
    },

    handleRemove: function handleRemove(file, fileList) {
      for (var i = 0; i < this.goods.gallery.length; i++) {
        // 这里存在两种情况
        // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.data.url
        //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
        // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
        var url;
        if (file.response === undefined) {
          url = file.url;
        } else {
          url = file.response.data.url;
        }

        if (this.goods.gallery[i] === url) {
          this.goods.gallery.splice(i, 1);
        }
      }
    },
    specChanged: function specChanged(label) {
      if (label === false) {
        this.specifications = [{ specification: '规格', value: '标准', picUrl: '' }];
        this.products = [{ id: 0, specifications: ['标准'], price: 0.00, number: 0, url: '' }];
      } else {
        this.specifications = [];
        this.products = [];
      }
    },
    uploadSpecPicUrl: function uploadSpecPicUrl(response) {
      this.specForm.picUrl = response.data.url;
    },
    handleSpecificationShow: function handleSpecificationShow() {
      this.specForm = { specification: '', value: '', picUrl: '' };
      this.specVisiable = true;
    },
    handleSpecificationAdd: function handleSpecificationAdd() {
      var index = this.specifications.length - 1;
      for (var i = 0; i < this.specifications.length; i++) {
        var v = this.specifications[i];
        if (v.specification === this.specForm.specification) {
          index = i;
        }
      }

      this.specifications.splice(index + 1, 0, this.specForm);
      this.specVisiable = false;

      this.specToProduct();
    },
    handleSpecificationDelete: function handleSpecificationDelete(row) {
      var index = this.specifications.indexOf(row);
      this.specifications.splice(index, 1);
      this.specToProduct();
    },
    specToProduct: function specToProduct() {
      if (this.specifications.length === 0) {
        return;
      }
      // 根据specifications创建临时规格列表
      var specValues = [];
      var spec = this.specifications[0].specification;
      var values = [];
      values.push(0);

      for (var i = 1; i < this.specifications.length; i++) {
        var aspec = this.specifications[i].specification;

        if (aspec === spec) {
          values.push(i);
        } else {
          specValues.push(values);
          spec = aspec;
          values = [];
          values.push(i);
        }
      }
      specValues.push(values);

      // 根据临时规格列表生产货品规格
      // 算法基于 https://blog.csdn.net/tyhj_sf/article/details/53893125
      var productsIndex = 0;
      var products = [];
      var combination = [];
      var n = specValues.length;
      for (var s = 0; s < n; s++) {
        combination[s] = 0;
      }
      var index = 0;
      var isContinue = false;
      do {
        var specifications = [];
        for (var x = 0; x < n; x++) {
          var z = specValues[x][combination[x]];
          specifications.push(this.specifications[z].value);
        }
        products[productsIndex] = { id: productsIndex, specifications: specifications, price: 0.00, number: 0, url: '' };
        productsIndex++;

        index++;
        combination[n - 1] = index;
        for (var j = n - 1; j >= 0; j--) {
          if (combination[j] >= specValues[j].length) {
            combination[j] = 0;
            index = 0;
            if (j - 1 >= 0) {
              combination[j - 1] = combination[j - 1] + 1;
            }
          }
        }
        isContinue = false;
        for (var p = 0; p < n; p++) {
          if (combination[p] !== 0) {
            isContinue = true;
          }
        }
      } while (isContinue);

      this.products = products;
    },
    handleProductShow: function handleProductShow(row) {
      this.productForm = _Object$assign({}, row);
      this.productVisiable = true;
    },

    uploadProductUrl: function uploadProductUrl(response) {
      this.productForm.url = response.data.url;
    },
    handleProductEdit: function handleProductEdit() {
      for (var i = 0; i < this.products.length; i++) {
        var v = this.products[i];
        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm);
          break;
        }
      }
      this.productVisiable = false;
    },
    handleAttributeShow: function handleAttributeShow() {
      this.attributeForm = {};
      this.attributeVisiable = true;
    },
    handleAttributeAdd: function handleAttributeAdd() {
      this.attributes.unshift(this.attributeForm);
      this.attributeVisiable = false;
    },
    handleAttributeDelete: function handleAttributeDelete(row) {
      var index = this.attributes.indexOf(row);
      this.attributes.splice(index, 1);
    }
  }
};