import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listOrder, shipOrder, refundOrder, detailOrder, listShipChannel } from '@/api/business/order';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import checkPermission from '@/utils/permission'; // 权限判断函数

var statusMap = {
  101: '未付款',
  102: '用户取消',
  103: '系统取消',
  201: '已付款',
  202: '申请退款',
  203: '已退款',
  301: '已发货',
  401: '用户收货',
  402: '系统收货'
};

export default {
  name: 'Order',
  components: { Pagination: Pagination },
  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: undefined,
      total: 0,
      listLoading: true,
      shipChannelList: [],
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined,
        name: undefined,
        orderStatusArray: [],
        sort: 'add_time',
        order: 'desc'
      },
      statusMap: statusMap,
      orderDialogVisible: false,
      orderDetail: {
        order: {},
        user: {},
        orderGoods: []
      },
      shipForm: {
        orderId: undefined,
        shipChannel: undefined,
        shipSn: undefined
      },
      shipDialogVisible: false,
      refundForm: {
        orderId: undefined,
        refundMoney: undefined
      },
      refundDialogVisible: false,
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
    this.getListShipChannel();
  },

  methods: {
    checkPermission: checkPermission,
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listOrder(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    getListShipChannel: function getListShipChannel() {
      var _this2 = this;

      listShipChannel().then(function (response) {
        _this2.shipChannelList = response.data.data.shipChannelList;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDetail: function handleDetail(row) {
      var _this3 = this;

      detailOrder(row.id).then(function (response) {
        _this3.orderDetail = response.data.data;
      });
      this.orderDialogVisible = true;
    },
    handleShip: function handleShip(row) {
      var _this4 = this;

      this.shipForm.orderId = row.id;
      this.shipForm.shipChannel = row.shipChannel;
      this.shipForm.shipSn = row.shipSn;

      this.shipDialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['shipForm'].clearValidate();
      });
    },
    confirmShip: function confirmShip() {
      var _this5 = this;

      this.$refs['shipForm'].validate(function (valid) {
        if (valid) {
          shipOrder(_this5.shipForm).then(function (response) {
            _this5.shipDialogVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '确认发货成功'
            });
            _this5.getList();
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleRefund: function handleRefund(row) {
      var _this6 = this;

      this.refundForm.orderId = row.id;
      this.refundForm.refundMoney = row.actualPrice;

      this.refundDialogVisible = true;
      this.$nextTick(function () {
        _this6.$refs['refundForm'].clearValidate();
      });
    },
    confirmRefund: function confirmRefund() {
      var _this7 = this;

      this.$refs['refundForm'].validate(function (valid) {
        if (valid) {
          refundOrder(_this7.refundForm).then(function (response) {
            _this7.refundDialogVisible = false;
            _this7.$notify.success({
              title: '成功',
              message: '确认退款成功'
            });
            _this7.getList();
          }).catch(function (response) {
            _this7.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this8 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单ID', '订单编号', '用户ID', '订单状态', '是否删除', '收货人', '收货联系电话', '收货地址'];
        var filterVal = ['id', 'orderSn', 'userId', 'orderStatus', 'isDelete', 'consignee', 'mobile', 'address'];
        excel.export_json_to_excel2(tHeader, _this8.list, filterVal, '订单信息');
        _this8.downloadLoading = false;
      });
    }
  }
};