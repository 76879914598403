import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listBrand, createBrand, updateBrand, deleteBrand, listCatAndAdmin } from '@/api/business/brand';
import { uploadPath } from '@/api/business/storage';
import { getToken } from '@/utils/auth';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Brand',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      uploadPath: uploadPath,
      list: undefined,
      total: 0,
      categoryList: [],
      adminList: [],
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: '',
        desc: '',
        floorPrice: undefined,
        picUrl: undefined,
        categoryIds: [],
        defaultCategoryId: undefined,
        adminId: undefined
      },
      dialogFormVisible: false,
      shareUrlDialogVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建',
        shareUrl: '店铺推广码'
      },
      rules: {
        name: [{ required: true, message: '品牌商名称不能为空', trigger: 'blur' }]
      },
      downloadLoading: false
    };
  },

  computed: {
    headers: function headers() {
      return {
        'X-Dts-Admin-Token': getToken()
      };
    }
  },
  created: function created() {
    this.getList();
    this.init();
  },

  methods: {
    init: function init() {
      var _this = this;

      listCatAndAdmin().then(function (response) {
        _this.categoryList = response.data.data.categoryList;
        _this.adminList = response.data.data.adminList;
      });
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listBrand(this.listQuery).then(function (response) {
        _this2.list = response.data.data.items;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: '',
        desc: '',
        floorPrice: undefined,
        picUrl: undefined,
        categoryIds: [],
        defaultCategoryId: undefined,
        adminId: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },

    uploadPicUrl: function uploadPicUrl(response) {
      this.dataForm.picUrl = response.data.url;
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createBrand(_this4.dataForm).then(function (response) {
            _this4.list.unshift(response.data.data);
            _this4.dialogFormVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateBrand(_this6.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this6.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this6.dataForm.id) {
                  var index = _this6.list.indexOf(v);
                  _this6.list.splice(index, 1, _this6.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this6.dialogFormVisible = false;
            _this6.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      deleteBrand(row).then(function (response) {
        _this7.$notify.success({
          title: '成功',
          message: '删除成功'
        });
        var index = _this7.list.indexOf(row);
        _this7.list.splice(index, 1);
      }).catch(function (response) {
        _this7.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleCategoryChange: function handleCategoryChange(value) {
      this.dataForm.defaultCategoryId = value[value.length - 1];
    },
    shareUrlDetail: function shareUrlDetail(row) {
      var _this8 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'shareUrl';
      this.shareUrlDialogVisible = true;
      this.$nextTick(function () {
        _this8.$refs['dataForm'].clearValidate();
      });
    },
    handleDownload: function handleDownload() {
      var _this9 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['品牌商ID', '品牌商名称', '介绍', '低价', '品牌商图片'];
        var filterVal = ['id', 'name', 'desc', 'floorPrice', 'picUrl'];
        excel.export_json_to_excel2(tHeader, _this9.list, filterVal, '品牌商信息');
        _this9.downloadLoading = false;
      });
    }
  }
};