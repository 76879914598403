var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      _vm.handleSetLineChartData("newVisitis")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-people" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": "peoples",
                          "class-name": "card-panel-icon"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v("用户数量")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.userTotal,
                          duration: 2600
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      _vm.handleSetLineChartData("messages")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-message" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": "message",
                          "class-name": "card-panel-icon"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v("商品数量")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.goodsTotal,
                          duration: 3000
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      _vm.handleSetLineChartData("purchases")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-money" },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": "message",
                          "class-name": "card-panel-icon"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v("货品数量")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.productTotal,
                          duration: 3200
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      _vm.handleSetLineChartData("shoppings")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "card-panel-icon-wrapper icon-shoppingCard"
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class": "money",
                          "class-name": "card-panel-icon"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v("订单数量")
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.orderTotal,
                          duration: 3600
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px"
          }
        },
        [_c("line-chart", { attrs: { "chart-data": _vm.userOrderCnt } })],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 16 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("bar-chart", { attrs: { "chart-data": _vm.orderAmts } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("pie-chart", { attrs: { "chart-data": _vm.categorySell } })],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }