import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchList, approveAgency, detailApprove } from '@/api/business/user';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'User',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        mobile: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      genderDic: ['未知', '男', '女'],
      levelDic: ['普通用户', 'VIP用户', '代理'],
      statusDic: ['可用', '禁用', '注销', '代理申请'],
      detailDialogVisible: false,
      agencyDetail: {},
      approveDialogVisible: false,
      approveForm: {
        userId: undefined,
        settlementRate: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      fetchList(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDetail: function handleDetail(row) {
      var _this2 = this;

      this.agencyDetail = {
        shareUrl: undefined,
        settlementRate: undefined
      };
      detailApprove(row.id).then(function (response) {
        _this2.agencyDetail = response.data.data;
      });
      this.detailDialogVisible = true;
    },
    handleApproveAgency: function handleApproveAgency(row) {
      var _this3 = this;

      this.approveForm.userId = row.id;

      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['approveForm'].clearValidate();
      });
    },
    confirmApprove: function confirmApprove() {
      var _this4 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          approveAgency(_this4.approveForm).then(function (response) {
            _this4.approveDialogVisible = false;
            _this4.$notify.success({
              title: '成功',
              message: '审批成功'
            });
            _this4.getList();
          }).catch(function (response) {
            _this4.$notify.error({
              title: '审批失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this5.list, filterVal, '用户信息');
        _this5.downloadLoading = false;
      });
    }
  }
};