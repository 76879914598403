//
//
//
//
//
//

import { statUser } from '@/api/business/stat';
import VeHistogram from 'v-charts/lib/histogram';

export default {
  components: { VeHistogram: VeHistogram },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;

    statUser().then(function (response) {
      _this.chartData = response.data.data;
      _this.chartSettings = {
        labelMap: {
          'users': '用户增长数'
        }
      };
      _this.chartExtend = {
        xAxis: { boundaryGap: true },
        series: {
          label: { show: true, position: 'top' }
        }
      };
    });
  }
};