import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';

export default {
  components: { SidebarItem: SidebarItem },
  computed: _extends({}, mapGetters(['permission_routers', 'sidebar']), {
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};