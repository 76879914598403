import request from '@/utils/request';

export function listStorage(query) {
  return request({
    url: '/storage/list',
    method: 'get',
    params: query
  });
}

export function createStorage(data) {
  return request({
    url: '/storage/create',
    method: 'post',
    data: data
  });
}

export function readStorage(data) {
  return request({
    url: '/storage/read',
    method: 'get',
    data: data
  });
}

export function updateStorage(data) {
  return request({
    url: '/storage/update',
    method: 'post',
    data: data
  });
}

export function deleteStorage(data) {
  return request({
    url: '/storage/delete',
    method: 'post',
    data: data
  });
}

var uploadPath = process.env.BASE_API + '/storage/create';
export { uploadPath };