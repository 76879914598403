import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listKeyword, createKeyword, updateKeyword, deleteKeyword } from '@/api/business/keyword';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Keyword',
  components: { Pagination: Pagination },
  data: function data() {
    return {
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: undefined,
        url: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        keyword: undefined,
        url: undefined,
        isHot: undefined,
        isDefault: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        keyword: [{ required: true, message: '关键词不能为空', trigger: 'blur' }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listKeyword(this.listQuery).then(function (response) {
        _this.list = response.data.data.items;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        keyword: undefined,
        url: undefined,
        isHot: undefined,
        isDefault: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createKeyword(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.data);
            _this3.dialogFormVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateKeyword(_this5.dataForm).then(function () {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _getIterator(_this5.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var v = _step.value;

                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);
                  _this5.list.splice(index, 1, _this5.dataForm);
                  break;
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this5.dialogFormVisible = false;
            _this5.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.errmsg
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      deleteKeyword(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '删除成功'
        });
        var index = _this6.list.indexOf(row);
        _this6.list.splice(index, 1);
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.errmsg
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['关键词ID', '关键词', '跳转链接', '是否推荐', '是否默认'];
        var filterVal = ['id', 'keyword', 'url', 'isHot', 'isDefault'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '关键词设置');
        _this7.downloadLoading = false;
      });
    }
  }
};