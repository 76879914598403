import _Object$keys from 'babel-runtime/core-js/object/keys';
import Vue from 'vue';

import Cookies from 'js-cookie';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import '@/styles/dts.scss';

import App from './App';
import router from './router';
import store from './store';

import _i18n from './lang'; // Internationalization
import './icons'; // icon
import './permission'; // permission control

import * as filters from './filters'; // global filters

import permission from '@/directive/permission/index.js'; // 权限判断指令

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});

Vue.directive('permission', permission);

// register global utility filters.
_Object$keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});