var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品介绍")]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品编号", prop: "goodsSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.goodsSn,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "goodsSn", $$v)
                      },
                      expression: "goods.goodsSn"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.name,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "name", $$v)
                      },
                      expression: "goods.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "专柜价格", prop: "counterPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.goods.counterPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "counterPrice", $$v)
                        },
                        expression: "goods.counterPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "当前价格", prop: "retailPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.goods.retailPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "retailPrice", $$v)
                        },
                        expression: "goods.retailPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否新品", prop: "isNew" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isNew,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isNew", $$v)
                        },
                        expression: "goods.isNew"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("新品")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("非新品")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否热卖", prop: "isHot" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isHot,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isHot", $$v)
                        },
                        expression: "goods.isHot"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("普通")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("热卖")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否在售", prop: "isOnSale" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isOnSale,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isOnSale", $$v)
                        },
                        expression: "goods.isOnSale"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("在售")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("未售")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif"
                      }
                    },
                    [
                      _vm.goods.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.goods.picUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "宣传画廊" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadPath,
                        headers: _vm.headers,
                        limit: 5,
                        "file-list": _vm.galleryFileList,
                        "on-exceed": _vm.uploadOverrun,
                        "on-success": _vm.handleGalleryUrl,
                        "on-remove": _vm.handleRemove,
                        multiple: "",
                        accept: ".jpg,.jpeg,.png,.gif",
                        "list-type": "picture-card"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品单位" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "件 / 个 / 盒" },
                    model: {
                      value: _vm.goods.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "unit", $$v)
                      },
                      expression: "goods.unit"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关键字" } },
                [
                  _vm._l(_vm.keywords, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { closable: "", type: "primary" },
                        on: {
                          close: function($event) {
                            _vm.handleClose(tag)
                          }
                        }
                      },
                      [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                    )
                  }),
                  _vm._v(" "),
                  _vm.newKeywordVisible
                    ? _c("el-input", {
                        ref: "newKeywordInput",
                        staticClass: "input-new-keyword",
                        attrs: { size: "small" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          }
                        },
                        model: {
                          value: _vm.newKeyword,
                          callback: function($$v) {
                            _vm.newKeyword = $$v
                          },
                          expression: "newKeyword"
                        }
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-keyword",
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.showInput }
                        },
                        [_vm._v("+ 增加")]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.categoryList,
                      "expand-trigger": "hover"
                    },
                    on: { change: _vm.handleCategoryChange },
                    model: {
                      value: _vm.categoryIds,
                      callback: function($$v) {
                        _vm.categoryIds = $$v
                      },
                      expression: "categoryIds"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属品牌商" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.goods.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "brandId", $$v)
                        },
                        expression: "goods.brandId"
                      }
                    },
                    _vm._l(_vm.brandList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    })
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品简介" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.brief,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "brief", $$v)
                      },
                      expression: "goods.brief"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品详细介绍" } },
                [
                  _c("editor", {
                    attrs: { init: _vm.editorInit },
                    model: {
                      value: _vm.goods.detail,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "detail", $$v)
                      },
                      expression: "goods.detail"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品规格")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { plain: true, type: "primary" },
              on: { click: _vm.handleSpecificationShow }
            },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.specifications } },
            [
              _c("el-table-column", {
                attrs: { property: "specification", label: "规格名" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "value", label: "规格值" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.value) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "picUrl", label: "规格图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.picUrl
                          ? _c("img", {
                              attrs: { src: scope.row.picUrl, width: "40" }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "250",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleSpecificationDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.specVisiable, title: "设置规格" },
              on: {
                "update:visible": function($event) {
                  _vm.specVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "specForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.specForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格名", prop: "specification" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.specification,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "specification", $$v)
                          },
                          expression: "specForm.specification"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格值", prop: "value" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "value", $$v)
                          },
                          expression: "specForm.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格图片", prop: "picUrl" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            headers: _vm.headers,
                            action: _vm.uploadPath,
                            "show-file-list": false,
                            "on-success": _vm.uploadSpecPicUrl,
                            accept: ".jpg,.jpeg,.png,.gif"
                          }
                        },
                        [
                          _vm.specForm.picUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.specForm.picUrl }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.specVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSpecificationAdd }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品库存")]),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.products } },
            [
              _c("el-table-column", {
                attrs: { property: "value", label: "货品规格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.specifications, function(tag) {
                        return _c("el-tag", { key: tag }, [
                          _vm._v(
                            "\n            " + _vm._s(tag) + "\n          "
                          )
                        ])
                      })
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "price", width: "100", label: "货品售价" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "number", width: "100", label: "货品数量" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "url", width: "100", label: "货品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.url
                          ? _c("img", {
                              attrs: { src: scope.row.url, width: "40" }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "100",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleProductShow(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.productVisiable, title: "设置货品" },
              on: {
                "update:visible": function($event) {
                  _vm.productVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "productForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.productForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品规格列", prop: "specifications" } },
                    _vm._l(_vm.productForm.specifications, function(tag) {
                      return _c("el-tag", { key: tag }, [
                        _vm._v("\n            " + _vm._s(tag) + "\n          ")
                      ])
                    })
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品售价", prop: "price" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.productForm.price,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "price", $$v)
                          },
                          expression: "productForm.price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品数量", prop: "number" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.productForm.number,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "number", $$v)
                          },
                          expression: "productForm.number"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品图片", prop: "url" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            headers: _vm.headers,
                            action: _vm.uploadPath,
                            "show-file-list": false,
                            "on-success": _vm.uploadProductUrl,
                            accept: ".jpg,.jpeg,.png,.gif"
                          }
                        },
                        [
                          _vm.productForm.url
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.productForm.url }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.productVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleProductEdit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品参数")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { plain: true, type: "primary" },
              on: { click: _vm.handleAttributeShow }
            },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.attributes } },
            [
              _c("el-table-column", {
                attrs: { property: "attribute", label: "商品参数名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "value", label: "商品参数值" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "100",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                _vm.handleAttributeDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.attributeVisiable, title: "设置商品参数" },
              on: {
                "update:visible": function($event) {
                  _vm.attributeVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "attributeForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.attributeForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品参数名称", prop: "attribute" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.attribute,
                          callback: function($$v) {
                            _vm.$set(_vm.attributeForm, "attribute", $$v)
                          },
                          expression: "attributeForm.attribute"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品参数值", prop: "value" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.attributeForm, "value", $$v)
                          },
                          expression: "attributeForm.value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.attributeVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAttributeAdd }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
            [_vm._v("更新商品")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }