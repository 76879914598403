import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
import { generateTitle } from '@/utils/i18n';
import { isExternal } from '@/utils';
import Item from './Item';
import AppLink from './Link';
import FixiOSBug from './FixiOSBug';

export default {
  name: 'SidebarItem',
  components: { Item: Item, AppLink: AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      onlyOneChild: null
    };
  },

  methods: {
    hasOneShowingChild: function hasOneShowingChild(children, parent) {
      var _this = this;

      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = _extends({}, parent, { path: '', noShowingChildren: true });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink: function isExternalLink(routePath) {
      return isExternal(routePath);
    },

    generateTitle: generateTitle
  }
};