//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchList, approveTrace } from '@/api/business/brokerage';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import checkPermission from '@/utils/permission'; // 权限判断函数

var statusMap = {
  0: '提现申请',
  1: '审批通过',
  2: '审批拒绝'
};

var typeMap = {
  0: '系统结算',
  1: '用户申请'
};

export default {
  name: 'Account',
  components: { Pagination: Pagination },
  filters: {
    statusFilter: function statusFilter(status) {
      return statusMap[status];
    },
    typeFilter: function typeFilter(type) {
      return typeMap[type];
    }
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        username: undefined,
        mobile: undefined,
        statusArray: [],
        sort: 'add_time',
        order: 'desc'
      },
      statusMap: statusMap,
      typeMap: typeMap,
      rules: {
        status: [{ required: true, message: '审批状态不能为空！', trigger: 'blur' }]
      },
      approveDialogVisible: false,
      approveForm: {
        id: undefined,
        status: undefined,
        traceMsg: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    checkPermission: checkPermission,
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      fetchList(this.listQuery).then(function (response) {
        _this.list = response.data.data.traceList;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleApprove: function handleApprove(row) {
      var _this2 = this;

      this.approveForm.id = row.id;
      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs['approveForm'].clearValidate();
      });
    },
    confirmApprove: function confirmApprove() {
      var _this3 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          approveTrace(_this3.approveForm).then(function (response) {
            _this3.approveDialogVisible = false;
            _this3.$notify.success({
              title: '成功',
              message: '审批成功'
            });
            _this3.getList();
          }).catch(function (response) {
            _this3.$notify.error({
              title: '审批失败',
              message: response.data.errmsg
            });
          });
        }
      });
    }
  }
};