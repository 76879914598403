var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", size: "mini", placeholder: "请输入类目ID" },
            model: {
              value: _vm.listQuery.id,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "id", $$v)
              },
              expression: "listQuery.id"
            }
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              size: "mini",
              placeholder: "请输入类目名称"
            },
            model: {
              value: _vm.listQuery.name,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/category/list"],
                  expression: "['GET /admin/category/list']"
                }
              ],
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["POST /admin/category/create"],
                  expression: "['POST /admin/category/create']"
                }
              ],
              staticClass: "filter-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("添加")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                size: "mini",
                type: "warning",
                icon: "el-icon-download"
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类目ID",
              prop: "id",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "类目名", prop: "name" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "iconUrl", label: "类目图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.iconUrl
                      ? _c("img", {
                          attrs: { src: scope.row.iconUrl, width: "40" }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", property: "picUrl", label: "类目图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.picUrl
                      ? _c("img", {
                          attrs: { src: scope.row.picUrl, width: "80" }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "关键字", prop: "keywords" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "简介",
              prop: "desc"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "级别", prop: "level" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.level === "L1" ? "primary" : "info"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.level === "L1" ? "一级类目" : "二级类目"
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "父类目ID", prop: "pid" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "200",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/category/update"],
                            expression: "['POST /admin/category/update']"
                          }
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/category/delete"],
                            expression: "['POST /admin/category/delete']"
                          }
                        ],
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类目名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "关键字", prop: "keywords" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.keywords,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "keywords", $$v)
                      },
                      expression: "dataForm.keywords"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "级别", prop: "level" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.onLevelChange },
                      model: {
                        value: _vm.dataForm.level,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "level", $$v)
                        },
                        expression: "dataForm.level"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "一级类目", value: "L1" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "二级类目", value: "L2" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dataForm.level === "L2"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "父类目", prop: "pid" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.dataForm.pid,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForm, "pid", $$v)
                            },
                            expression: "dataForm.pid"
                          }
                        },
                        _vm._l(_vm.catL1, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        })
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目图标", prop: "iconUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadIconUrl,
                        accept: ".jpg,.jpeg,.png,.gif"
                      }
                    },
                    [
                      _vm.dataForm.iconUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.dataForm.iconUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目图片", prop: "picUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif"
                      }
                    },
                    [
                      _vm.dataForm.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.dataForm.picUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类目简介", prop: "desc" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "desc", $$v)
                      },
                      expression: "dataForm.desc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }